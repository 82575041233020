import * as React from 'react'
/* This example requires Tailwind CSS v2.0+ */
import Layout from "../components/layout"
import ExtendIProduktion from "../images/extend_i_produktion.webp"

//TODO Index - More content about main products

export const Head = () => (
  <>
    <title>Consol - Manufacturer of Lens Care and Eye Care Products</title>
    <meta name="description" content="Consol - Manufacturer of medical devices for lens care and eye care" />
  </>
)

export default function Page() {
  return (
    <Layout>

      <main className="lg:relative">
        <div className="mx-auto max-w-6xl w-full pt-16 pb-20 text-center lg:py-18 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-medium text-gray-900 sm:text-5xl md:text-6xl lg:text-6xl xl:text-6xl">
              <span className="block xl:inline">Competently manufacturing</span>{' '}
              <span className="block text-brand xl:inline">lens care & eye care products</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              From our aseptic filling plant in Gothenburg Sweden, we supply an established and growing base of small and large
              customers in the Nordic countries and the EU with our offering of original brand and private label since 1989.
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow hidden">
                <a
                  href="/"
                  className="w-full flex items-center justify-center px-8 py-3
                  border border-transparent text-base font-medium rounded-md
                  text-white bg-brand hover:bg-brand-dark md:py-4 md:text-lg md:px-10"
                >
                  Get started
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 hidden">
                <a
                  href="/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Live demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:flex relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={ExtendIProduktion}
            alt="Aseptisk fyllning av flaskor i renrum"
            width="1024"
            height="460"
          />
        </div>
      </main>
    </Layout>
  )
}

